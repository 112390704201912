let commonFn = {
	setPermissionTree: setPermissionTree,
	timeFormat: timeFormat,
	timeFix: timeFix,
	secondaryMenu: secondaryMenu
}
function timeFix() {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}
// 将日期选择器时间转换成yyyy-MM-dd
function timeFormat(intDate, format) {
	var date = new Date(intDate);
	if (/(y+)/.test(format)) {
		format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	var o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds()
	};
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(format)) {
			var str = o[k] + '';
			format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
		}
	}
	return format;
}
// 处理组织树
function setPermissionTree(intData) {
	const treeData = intData.map(item => {
		if (item.children) {
			return {
				...item,
				label: item.displayName,
				value: item.resourceCode,
				children: setPermissionTree(item.children)
			}
		} else {
			return {
				...item,
				label: item.displayName,
				value: item.resourceCode,
			}
		}
	})
	return treeData
}
function secondaryMenu(intRoute) {
    console.log("学生信息二级路由>>>>", intRoute)
    let routes = intRoute.map((item, index) => {
    	return {
    		sortId: item.sortId,
    		parentId: item.parentId,
    		id: item.id,
    		label: item.displayName,
    		value: index + 1
    	}
    })
	return routes
}
export default commonFn