<template>
	<div>
		<div class="handle_form_upload " v-if="fileData">
			<div class="files-flex">
				<div class="files-left">
					<el-image v-if="getFileType(fileName)" fit="cover" class="file-icon" :src="fileData" :preview-src-list="[fileData]">
					</el-image>
					<!-- <img @click="previewImage" v-if="getFileType(fileName)" :src="fileData" alt="" class="file-icon"> -->
					<img v-else src="@/assets/images/dashboard/file-icon.png" alt="" class="file-icon">
					<span>{{fileName}}</span>
				</div>
				<div class="files-right" @click="handleUpload">下载</div>
			</div>
			<div v-if="showReUpload" @click="reUpload" class="files-right m-l">重新上传</div>
			<!-- <span class="form_upload_box" @click="handleUpload">
				<img src="@/assets/images/dashboard/downLoad.png" class="form_upload_icon" />点击下载
			</span>
			<span v-if="showReUpload" @click="reUpload" class="form_upload_again">重新上传</span> -->
		</div>
		<div v-else>
			<el-upload class="upload-demo" :action="upLoadUrl" :show-file-list="false" :before-upload="beforeUpload">
				<div class="handle_form_upload">
					<span class="form_upload_box">
						<img src="@/assets/images/dashboard/upLoad.png" class="form_upload_icon" />点击上传
					</span>
					<span class="form_upload_tip">注：可上传文本和图片</span>
				</div>
			</el-upload>
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload"></el-upload>
		<!-- <preview-image @closePreview="closePreview" :isOpen="preVisible" :url="preUrl"/> -->
	</div>
</template>
<script>
	import {
		getImgSrc,
		upLoadFile
	} from "@/api/OSS";
	import {
		saveAs
	} from 'file-saver'
	export default {
		props: {
			fileSrc: {
				type: String,
				default: function() {
					return "";
				}
			},
			fileNameP: {
				type: String,
				default: function() {
					return "";
				}
			},
			fileNum: {
				type: Number,
				default: 0
			},
			showReUpload: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				upLoadUrl: "",
				fileData: "",
				showUpload: false,
				fileName: "",
				preVisible: false,
				preUrl: ""
			};
		},
		watch: {
			fileSrc: {
				async handler(newVal, oldVal) {
					console.log("fileList", newVal)
					this.fileData = newVal
				},
				immediate: true,
			},
			fileNameP: {
				async handler(newVal, oldVal) {
					console.log("fileName", newVal)
					this.fileName = newVal
				},
				immediate: true,
			}
		},
		methods: {
			reUpload() {
				document.querySelector(".uploadImage input").click();
			},
			beforeUpload(file) {
				console.log(file)
				let fileName = file.name;
				let fileType = file.type;
				let pointIndex = fileName.lastIndexOf('.')
				let type = fileName.substring(pointIndex + 1, fileName.length).toLowerCase()
				console.log("fileType", type)
				if(type == 'txt'||type == 'zip') {
					this.$message({
						message: "暂不支持txt、zip文件格式~",
						type: "error"
					});
					return false;
				}
				getImgSrc({
					fileName: fileName
				}).then((res) => {
					if (res.code != 0) return;
					this.upLoadUrl = res.data.uploadUrl;
					console.log("获取文件上传地址>>>>>", res.data.uploadUrl);
					upLoadFile(file, this.upLoadUrl, fileType).then((res) => {
						const fileUrl = res.config.url.substring(
							0,
							res.config.url.indexOf("?")
						);
						// if (this.limitUpLoad == 1) {
						// 	this.fileData = []
						// }
						this.fileData = fileUrl;
						this.fileName = fileName
						console.log("文件上传成功fileData>>>>>", this.fileData);
						this.$emit("successUpload", {
							fileData: this.fileData,
							number: this.fileNum,
							fileName: fileName
						});
					});
				});
			},
			getFileType(fileStr) {
				let index = fileStr.lastIndexOf('.')
				let fileType = ''
				if (index != -1) {
					fileType = fileStr.substring(index + 1, fileStr.length).toLowerCase()
					// console.log('fileType', fileType)
					if (fileType == 'jpg' || fileType == 'png' || fileType == 'jpeg' || fileType == 'gif' || fileType ==
						'webp') {
						return true
					} else {
						return false
					}
				}
			},
			handleUpload() {
				let pointIndex = this.fileName.lastIndexOf('.')
				let fileName = ""
				let fileType = ""
				if (pointIndex != -1) {
					fileName = this.fileName.substring(0, pointIndex).toLowerCase()
					fileType = this.fileName.substring(pointIndex + 1, this.fileName.length).toLowerCase()
					// console.log("fileType", fileType)
				}
				if (fileType == 'txt') {
					let xhr = new XMLHttpRequest();
					xhr.open("get", this.fileData, true);
					xhr.responseType = "blob";
					xhr.onload = function() {
						if (this.status == 200) {
							const reader = new FileReader()
							reader.onload = function() {
								// console.log('reader.result', reader.result)
								var blob = new Blob([reader.result], {
									type: 'text/plain;charset=utf-8'
								})
								saveAs(blob, fileName + '.txt')
							}
							reader.readAsText(this.response);
						}
					};
					xhr.send();
				} else {
					window.open(this.fileData);
				}
			},
			previewImage() {
				this.preVisible = true
				this.preUrl = this.fileData
				this.$emit("previewImage")
			},
			closePreview() {
				this.preUrl = ""
				this.preVisible = false
			},
		}
	};
</script>
<style lang="scss" scoped>
	.file-name{
		width:200px;
		word-break: break-all
	}
	.files-flex{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #EEEEEE;
		border-radius: 4px;
		padding: 10px 14px;

		.files-left {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 14px;

			.file-icon {
				width: 26px;
				height: 28px;
				margin-right: 8px;
			}
		}

	}

	.files-right {
		min-width: 60px;
		color: #5BA897;
		font-size: 14px;
		cursor: pointer;
		text-align: center;
	}

	.m-l {
		margin-left: 6px;
	}

	.handle_form_upload {
		display: flex;
		align-items: center;

		// flex-direction: column;
		.form_upload_box {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 128px;
			line-height: 34px;
			background: #ffffff;
			border: 1px solid #eeeeee;
			border-radius: 4px;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #000000;
			cursor: pointer;

			.form_upload_icon {
				width: 14px;
				height: 14px;
				margin-right: 10px;
			}
		}

		.form_upload_tip {
			font-size: 12px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 17px;
			color: #999999;
			margin-left: 5px;
		}

		.form_upload_again {
			width: 100px;
			font-size: 12px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 30px;
			color: #999999;
			margin-top: 5px;
			cursor: pointer;
		}
	}
</style>
